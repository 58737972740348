import FeatureGates from '@atlaskit/feature-gate-js-client';

const EXPERIMENT_NAME = 'confluence_surface_import';
const COHORT_KEY = 'cohort';

enum Cohorts {
	ENROLLED = 'enrolled',
	NOT_ENROLLED = 'not-enrolled',
	CONTROL = 'control',
	EXPERIMENT = 'experiment',
}

export const useIsEligibleForSurfaceImport = (): boolean => {
	const isEligible =
		FeatureGates.getExperimentValue<Cohorts>(EXPERIMENT_NAME, COHORT_KEY, Cohorts.NOT_ENROLLED) ===
		Cohorts.EXPERIMENT;

	return isEligible;
};
